import React, { useState } from 'react'
import Logo from 'js/components/Logo'
import { useDispatch, useSelector } from 'react-redux'
import { logOut, selectIsLoggedIn } from 'js/store/userSlice'
import { Link, useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import LanguageSwitcher from 'js/components/Header/LanguageSwitcher'
import LoginOverlay from 'js/components/Login/LoginOverlay'
import cn from 'classnames'
import { supportedLanguages, AdjustStartPageUrl } from 'js/constants'
import useLocale from 'js/hooks/useLocale'

const navigationItemClasses =
  'text-white active:text-LSWhiteLinkActive focus:text-LSWhiteLinkHover hover:text-LSWhiteLinkHover transition inline-block ml-12 lg:ml-20 lg:mr-20'
const linkNavigationItemClasses = `lg:type-20 type-[17px] ${navigationItemClasses} border-b-1 border-white/0 hover:border-LSWhiteLinkHover/100`

const navSectionClasses = 'flex items-center md:w-1/3'

const AccountLink = () => {
  return (
    <Link
      data-uid="account_header_button"
      to="/account/"
      className={linkNavigationItemClasses}
    >
      <FormattedMessage id="Header.menuBar.account" defaultMessage="Account" />
    </Link>
  )
}

const LogOutButton = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <button
      data-uid="logout_header_button"
      onClick={(e) => {
        e.preventDefault()
        dispatch(logOut())
        navigate('/')
      }}
      className={linkNavigationItemClasses}
    >
      <FormattedMessage id="Header.menuBar.logOut" defaultMessage="Log out" />
    </button>
  )
}

export const Download = () => {
  const { language } = useLocale()
  let downloadLink = AdjustStartPageUrl
  if (
    supportedLanguages[language] &&
    supportedLanguages[language].onboardingQuestionnaire
  ) {
    downloadLink = supportedLanguages[language].onboardingQuestionnaire
  }

  return (
    <a
      href={downloadLink}
      className={cn(
        'rounded-8 border-1 px-20 py-8 font-demibold uppercase leading-[1] text-white type-14',
        navigationItemClasses,
      )}
    >
      <div style={{ transform: 'translateY(1px)' }}>
        <FormattedMessage id="navigation_download" />
      </div>
    </a>
  )
}

export function Navigation() {
  const [showLogin, setShowLogin] = useState(false)
  const isLoggedIn = useSelector(selectIsLoggedIn)

  return (
    <>
      <nav className="px-16 lg:px-12">
        <ul className="flex items-center">
          <div
            className={cn(
              navSectionClasses,
              'xs:justify-between md:justify-start',
            )}
          >
            <li className="hidden grow-0 md:inline">
              <Link
                className={linkNavigationItemClasses}
                to="/nutrition-explained"
              >
                <FormattedMessage id="navigation_articles" />
              </Link>
            </li>
            <li className="hidden grow-0 md:inline">
              <Link className={linkNavigationItemClasses} to="/work">
                <FormattedMessage id="navigation_for_work" />
              </Link>
            </li>
          </div>
          <li className="order-first grow md:order-none md:text-center">
            <Logo className="text-white" />
          </li>
          <div className={cn(navSectionClasses, 'justify-end')}>
            <li className="grow-0 md:inline">
              <LanguageSwitcher isLight inverted />
            </li>
            {isLoggedIn ? (
              <>
                <li className="grow-0 sm:order-first md:order-none md:inline">
                  <AccountLink />
                </li>
                <li className="grow-0 sm:order-first md:order-none md:inline">
                  <LogOutButton />
                </li>
              </>
            ) : (
              <li className="grow-0 sm:order-first md:order-none md:inline">
                <button
                  type="button"
                  data-uid="login_header_button"
                  onClick={() => setShowLogin(true)}
                  className={cn('cursor-pointer', linkNavigationItemClasses)}
                >
                  <FormattedMessage
                    id="Header.menuBar.logIn"
                    defaultMessage="Log in"
                  />
                </button>
              </li>
            )}
            <li className="hidden grow-0 md:inline">
              <Download />
            </li>
          </div>
        </ul>
      </nav>
      {showLogin && (
        <LoginOverlay goToAccount={true} onClose={() => setShowLogin(false)} />
      )}
    </>
  )
}
