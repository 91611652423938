export const AdjustStartPageUrl = 'https://app.adjust.com/112zb57z_116hd4yn'

export const supportedLanguages = {
  sv: {
    onboardingQuestionnaire:
      'https://onboarding.lifesum.com/weight-management-sw',
  },
  en: {
    onboardingQuestionnaire:
      'https://onboarding.lifesum.com/weight-management-us',
  },
  de: {
    onboardingQuestionnaire:
      'https://onboarding.lifesum.com/weight-management-de',
  },
  no: {
    onboardingQuestionnaire:
      'https://onboarding.lifesum.com/weight-management-no',
  },
  da: {
    onboardingQuestionnaire:
      'https://onboarding.lifesum.com/weight-management-dk',
  },
  nl: {
    onboardingQuestionnaire:
      'https://onboarding.lifesum.com/weight-management-nl',
  },
}
