import React, { useEffect } from 'react'
import DefaultLayout from '../../components/DefaultLayout'
import cn from 'classnames'
import Awards from './assets/awards.svg?svgr-webpack'
import AwardsMobile from './assets/awards-mobile.svg?svgr-webpack'
import AsSeenLogos from './assets/as-seen-logos.svg?svgr-webpack'
import AsSeenLogosMobile from './assets/as-seen-logos-mobile.svg?svgr-webpack'
import Ratings from './assets/ratings.svg?svgr-webpack'
import { HeadTags } from './HeadTags'
import { Navigation } from './Navigation'
import { homeImages } from 'js/imageSources'
import AppButtons from 'js/components/AppButtons/AppButtons'
import './styles.scss'
import Footer from 'js/components/Footer/Footer'
import { setSessionItem } from 'js/storeManager'
import { supportedLanguages, AdjustStartPageUrl } from 'js/constants'
import LSButton from 'js/components/LSButton'
import { FeatureImage } from 'js/components/FeatureImage'
import { FormattedMessage, useIntl } from 'react-intl'
import { reviewCount } from 'lifesum-shared/reviews'
import useLocale from 'js/hooks/useLocale'
import Fold from 'js/components/Fold'

type SectionProps = {
  children: React.ReactNode
  style?: React.CSSProperties
  className?: string
  defaultTopPadding?: boolean
}

function Section({
  children,
  className,
  style,
  defaultTopPadding = true,
}: SectionProps) {
  return (
    <div
      className={cn('px-16 pb-24 md:px-64 lg:px-80', className, {
        'pt-48 lg:pt-128': defaultTopPadding,
      })}
      style={style}
    >
      <div className="mx-auto max-w-[1280px]">{children}</div>
    </div>
  )
}

const fonts = {
  smallHeader: 'type-24 text-center font-demibold',
  h1: 'type-48 lg:type-56 xl:type-64 TTNormsPro font-bold',
  subHeader: 'type-24 lg:type-28 xl:type-32',
  h3: 'type-32 lg:type-48',
  body: 'type-17 lg:type-20',
  ingress: 'type-40 lg:type-56 TTNormsPro mb-24 font-demibold',
  sectionHeading:
    'type-48 lg:type-56 xl:type-64 TTNormsPro text-center font-bold',
}

function Hero() {
  const intl = useIntl()
  const { language } = useLocale()
  let buttonLink = AdjustStartPageUrl

  if (
    supportedLanguages[language] &&
    supportedLanguages[language].onboardingQuestionnaire
  ) {
    buttonLink = supportedLanguages[language].onboardingQuestionnaire
  }
  return (
    <div className="flex min-h-[calc(92vh-96px)] px-16 pb-24 md:px-64 lg:min-h-[calc(80vh-144px)] lg:px-80">
      <div className="mx-auto flex w-full max-w-[1280px] items-end md:items-center">
        <hgroup
          className={cn(
            'w-full pb-12 text-center md:w-3/4 md:text-left lg:w-1/2',
          )}
        >
          {intl.messages['home_heading_1'] && (
            <h1 className={fonts.h1}>
              <FormattedMessage id="home_heading_1" />
            </h1>
          )}
          <h1 className={cn('mb-24 lg:mb-[28px]', fonts.h1)}>
            <FormattedMessage id="home_heading" />
          </h1>
          <p
            className={cn('mb-24 max-w-[600px] lg:mb-[28px]', fonts.subHeader)}
          >
            <FormattedMessage id="home_tagline" />
          </p>
          <LSButton
            variant="primary"
            link={true}
            href={buttonLink}
            upperCase={true}
          >
            <FormattedMessage id="home_get_free_app" />
          </LSButton>
        </hgroup>
      </div>
    </div>
  )
}

function Beta() {
  useEffect(() => {
    setSessionItem('bannerShown', true)
  }, [])
  const { language } = useLocale()
  const intl = useIntl()

  useEffect(() => {
    if (language === 'de') {
      // https://tech-docs.zoominfo.com/zoominfo-script-implementation-guide.pdf
      const script = document.createElement('script')
      script.id = 'piwik-script'
      script.innerHTML = `
    var _spef = _spef || []; _spef.push(["enableLinkTracking"]); _spef.push(["trackPageView"]); (function () {
      var u = "//trck.spoteffects.net/analytics/"; _spef.push(['setTrackerUrl', u + 'piwik.php']); _spef.push(['setSiteId',1162]);
      var d = document, g = d.createElement("script"), s = d.getElementsByTagName("script")[0];
      g.type = "text/javascript"; g.defer = true;
      g.async = true;
      g.src = u + "spef.min.js"; s.parentNode.insertBefore(g, s);
      })();
    `
      document.body.appendChild(script)
      return () => {}
    }
  }, [language])

  return (
    <div className="bg-BgMain">
      <DefaultLayout>
        <HeadTags />
        <div className="page-home-hero-background min-h-[92vh] bg-cover pt-24 text-white lg:min-h-[80vh] lg:pt-36">
          <Navigation />
          <Hero />
        </div>
        <Section defaultTopPadding={false} className="pt-[24px] lg:pt-96">
          <div className="grid items-center lg:grid-cols-8 lg:gap-12">
            <FeatureImage
              image={homeImages.phoneScreen}
              className="mb-20 lg:col-span-4 lg:mb-0"
            />
            <div className="text-center lg:col-span-4 lg:text-left">
              <div className={cn('mb-30 lg:mb-36', fonts.h1)}>
                <FormattedMessage id="home_eat_well" />
              </div>
              <p className={cn('mb-30 lg:mb-36', fonts.subHeader)}>
                <FormattedMessage id="home_lasting_change" />
              </p>
              <p className={cn('mb-30 lg:mb-36', fonts.subHeader)}>
                <b className={cn('font-demibold')}>
                  {new Intl.NumberFormat(language).format(reviewCount)}
                </b>{' '}
                <FormattedMessage
                  id="homepage_reviews_copy"
                  defaultMessage="reviews and counting!"
                />
              </p>
              <Ratings className="mb-30 max-w-[264px] lg:mb-36 lg:max-w-[318px]" />
              <AppButtons />
            </div>
          </div>
        </Section>
        <Fold />
        <Section className="pb-48 lg:pb-64 lg:pt-0">
          <Awards className="hidden max-w-full md:block" />
          <AwardsMobile className="max-w-full md:hidden" />
        </Section>
        <Section className="bg-BgContent">
          <SectionHeading
            smallHeader={<FormattedMessage id="home_features" />}
            heading={<FormattedMessage id="home_features_heading" />}
          />
          <ul>
            {intl.messages['home_features_MMT_heading'] && (
              <Feature>
                <FeatureImage
                  image={homeImages.featuresMultimodalTracking}
                  className="col-span-6 col-start-3 row-start-1 lg:col-span-4 lg:col-start-1"
                />
                <FeatureImage
                  image={homeImages.featuresMMTApp}
                  className={`
                col-span-4
                col-start-1 row-start-1 mt-[80%] drop-shadow-DefaultShadow lg:col-span-3
                lg:col-start-3 lg:mt-[120%]
                lg:-translate-x-[5%]
                lg:pr-[20%]
                xl:col-start-4 xl:mt-[100%]
                xl:translate-x-0
                `}
                />
                <div className="col-span-8 lg:col-span-4 lg:col-start-5 lg:row-start-1 lg:mb-[20%] lg:mt-30">
                  <p className={fonts.ingress}>
                    <FormattedMessage id="home_features_MMT_heading" />
                  </p>

                  <p className="lg:pr-24">
                    <FormattedMessage id="home_features_MMT_body" />
                  </p>
                </div>
              </Feature>
            )}
            <Feature>
              <FeatureImage
                image={homeImages.featuresMacrosLifestyle}
                className={`
                col-span-7 col-start-1
                row-start-1 mt-[20%] lg:col-span-5 lg:col-start-4 lg:mt-0

                `}
              />
              <FeatureImage
                image={homeImages.featuresMacrosApp}
                className={`
                col-span-5 col-start-4 row-start-1 drop-shadow-DefaultShadow lg:col-span-3
                lg:col-start-5 lg:row-start-1
                lg:-ml-[20%] lg:mt-[100%]
                `}
              />
              <div className="col-span-8 lg:col-span-3 lg:col-start-1 lg:row-start-1 lg:mt-[36%]">
                <p className={cn(fonts.ingress)}>
                  <FormattedMessage id="home_features_energy_heading" />
                </p>
                <p className="md:pr-24">
                  <FormattedMessage id="home_features_energy_body" />
                </p>
              </div>
            </Feature>
            <Feature>
              <FeatureImage
                image={homeImages.featuresLifescoreLifestyle}
                className="col-span-6 col-start-3 row-start-1 lg:col-span-4 lg:col-start-1"
              />
              <FeatureImage
                image={homeImages.featuresLifescoreApp}
                className={`
                col-span-4
                col-start-1 row-start-1 mt-[80%] drop-shadow-DefaultShadow lg:col-span-3
                lg:col-start-2 lg:mt-[100%]
                lg:pr-[20%]
                `}
              />
              <div className="col-span-8 self-center lg:col-span-4 lg:col-start-5 lg:row-start-1 lg:mb-[20%]">
                <p className={fonts.ingress}>
                  <FormattedMessage id="home_features_lifescore_heading" />
                </p>

                <p className="lg:pr-24">
                  <FormattedMessage id="home_features_lifescore_body" />
                </p>
              </div>
            </Feature>
            <Feature className="lg:mb-[150px]">
              <FeatureImage
                image={homeImages.featuresMealPlansLifestyle}
                className={`
                  col-span-6 col-start-3 row-start-1
                  lg:col-span-4 lg:col-start-5
                `}
              />
              <FeatureImage
                image={homeImages.featuresMealPlansApp}
                className={`
                  col-span-7 col-start-1 row-start-1
                  mt-[85%] drop-shadow-DefaultShadowDarker
               lg:col-span-4
                  lg:col-start-3 lg:ml-[40%] lg:mt-[100%]
                `}
              />
              <div
                className={`
                lg
                col-span-8
                lg:col-span-4
                lg:col-start-1 lg:row-start-1
                lg:mt-[20%]
                `}
              >
                <p className={cn('md:pr-72', fonts.ingress)}>
                  <FormattedMessage id="home_features_meal_plans_heading" />
                </p>

                <p className="md:pr-24">
                  <FormattedMessage id="home_features_meal_plans_body" />
                </p>
              </div>
            </Feature>
            <Feature className="lg:grid-rows-[auto_auto]">
              <FeatureImage
                image={homeImages.featuresBarcodeScannerLifestyle}
                className={`
                col-span-7 col-start-1 row-start-1 row-end-1
                lg:col-span-6 lg:col-start-1
                `}
              />
              <div
                className={`
                col-span-3 col-start-1 row-start-1 ml-[10%]
                mt-[75%]
                lg:row-end-3
                lg:mt-[55%] lg:pr-[15%]
                `}
              >
                <FeatureImage
                  image={homeImages.featuresBarcodeScannerApp}
                  className="drop-shadow-DefaultShadowDarker"
                />
              </div>
              <div
                className={`
                col-span-8
                md:col-span-4
                lg:col-span-4 lg:col-start-5
                lg:row-start-2
                `}
              >
                <p className={cn('md:pr-72', fonts.ingress)}>
                  <FormattedMessage id="home_features_barcode_scanner_heading" />
                </p>
                <p className="md:pr-24">
                  <FormattedMessage id="home_features_barcode_scanner_body" />
                </p>
              </div>
            </Feature>
            <Feature className="lg:mb-256">
              <FeatureImage
                image={homeImages.featuresTrackingLifestyle}
                className={`
                  col-span-6 col-start-3
                  row-start-1 hidden lg:col-span-4
                  lg:col-start-5 lg:block
                `}
              />
              <FeatureImage
                image={homeImages.featuresTrackingLifestylePortrait}
                className={`
                  col-span-6
                  col-start-3 row-start-1 lg:col-span-4
                  lg:col-start-5 lg:hidden
                `}
              />
              <div
                className={`
                  col-span-5 col-start-1 row-start-1
                  mt-[15%] lg:col-span-3
                  lg:col-start-3 lg:mt-[85%]
                  lg:pr-[5%]
              `}
              >
                <FeatureImage
                  image={homeImages.featuresTrackingApp}
                  className="drop-shadow-DefaultShadow"
                />
              </div>
              <div className="col-span-8 lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:mt-[15%]">
                <p className={cn('md:pr-72', fonts.ingress)}>
                  <FormattedMessage id="home_features_tracking_heading" />
                </p>

                <p className="md:pr-24">
                  <FormattedMessage id="home_features_tracking_body" />
                </p>
              </div>
            </Feature>
          </ul>
        </Section>
        <Section>
          <SectionHeading
            smallHeader={<FormattedMessage id="home_customer_stories" />}
            heading={<FormattedMessage id="home_customer_stories_heading" />}
          />
          <ul className="relative mb-80 grid grid-cols-8 gap-5 lg:mb-[188px] lg:grid-rows-[auto_auto] lg:gap-12">
            <li
              className={`
              col-span-8
              lg:col-span-3 lg:col-start-1 lg:row-start-1
            `}
            >
              <TestimonialsImage
                image={homeImages.testimonialHelene}
                squareImage={homeImages.testimonialHeleneSquare}
              />
              <Quote
                name={
                  <FormattedMessage id="home_customer_stories_helene_name" />
                }
              >
                <FormattedMessage id="home_customer_stories_helene_quote" />
              </Quote>
            </li>
            <li
              className={`
              col-span-8
              lg:col-span-3 lg:col-start-6 lg:row-start-1
              lg:mt-[50%]
            `}
            >
              <TestimonialsImage
                image={homeImages.testimonialBjarne}
                squareImage={homeImages.testimonialBjarneSquare}
              />
              <Quote
                name={
                  <FormattedMessage id="home_customer_stories_bjarne_name" />
                }
              >
                <FormattedMessage id="home_customer_stories_bjarne_quote" />
              </Quote>
            </li>
            <li
              className={`
                col-span-8
                lg:col-span-3 lg:col-start-2 lg:row-start-2
                lg:mt-[50%]
              `}
            >
              <TestimonialsImage
                image={homeImages.testimonialMarianne}
                squareImage={homeImages.testimonialMarianneSquare}
              />
              <Quote
                name={
                  <FormattedMessage id="home_customer_stories_marianne_name" />
                }
              >
                <FormattedMessage id="home_customer_stories_marianne_quote" />
              </Quote>
            </li>
          </ul>
        </Section>
        <Section defaultTopPadding={false}>
          <h3 className={cn('pb-40 lg:pb-72', fonts.smallHeader)}>
            <FormattedMessage id="home_as_seen_in" />
          </h3>
          <div className="pb-96 text-center lg:pb-128">
            <AsSeenLogos className="hidden w-full lg:block" />
            <div className="mx-20">
              <AsSeenLogosMobile className="w-full lg:hidden" />
            </div>
          </div>
        </Section>
        <Section className="page-home-mission bg-BgContent text-center">
          <div className="grid grid-cols-8">
            <hgroup className="contents">
              <h4
                className={cn(
                  'col-span-8 lg:col-span-4 lg:col-start-3',
                  fonts.smallHeader,
                )}
              >
                <FormattedMessage id="home_mission_heading" />
              </h4>
              <p
                className={cn(
                  'col-span-8 lg:col-span-6 lg:col-start-2 lg:mx-24',
                  'mb-40',
                  fonts.sectionHeading,
                )}
              >
                <FormattedMessage id="home_mission_ingress" />
              </p>
            </hgroup>
          </div>
          <div className="grid grid-cols-8 lg:pb-[140px]">
            <div className="col-span-8 lg:col-span-4 lg:col-start-3">
              <p className="lg:48 mb-40">
                <FormattedMessage id="home_mission_body" />
              </p>
              <p className={fonts.sectionHeading}>
                {intl.messages['home_mission_egress'] ? (
                  <FormattedMessage id="home_mission_egress" />
                ) : (
                  <FormattedMessage id="home_heading" />
                )}
              </p>
            </div>
          </div>
        </Section>
        <FeatureImage
          image={homeImages.missionMobile}
          className="bg-BgContent lg:hidden"
        />
        <Footer flat={true} beta={true} />
      </DefaultLayout>
    </div>
  )
}

export default Beta

function Quote({
  className,
  children,
  name,
}: {
  children: React.ReactNode
  name: React.ReactNode
  className?: string
}) {
  return (
    <div className={cn('mb-40 mt-24 ', className)}>
      <figure>
        <blockquote className="TTNormsPro type-16 mb-24 lg:type-20">
          &ldquo;{children}&rdquo;
        </blockquote>
        <figcaption className="text-TypeSub type-20">— {name}</figcaption>
      </figure>
    </div>
  )
}

function Feature({
  children,
  className,
}: {
  className?: string
  children?: React.ReactNode
}) {
  return (
    <li
      className={cn(
        'relative mb-80 grid grid-cols-8 gap-5 lg:mb-[188px] lg:gap-12',
        className,
      )}
    >
      {children}
    </li>
  )
}

type ImageType = React.ComponentProps<typeof FeatureImage>['image']

function TestimonialsImage({
  image,
  squareImage,
}: {
  image: ImageType
  squareImage: ImageType
}) {
  return (
    <>
      <FeatureImage image={image} className="hidden lg:block" />
      <FeatureImage image={squareImage} className="lg:hidden" square={true} />
    </>
  )
}

type SectionHeadingProps = {
  smallHeader: React.ReactNode
  heading: React.ReactNode
  className?: string
}
function SectionHeading({
  smallHeader,
  heading,
  className,
}: SectionHeadingProps) {
  return (
    <hgroup className={className} style={{ maxWidth: '70%', margin: '0 auto' }}>
      <h4 className={cn('mb-12 lg:mb-24', fonts.smallHeader)}>{smallHeader}</h4>
      <p className={cn('mb-56 lg:mb-[160px]', fonts.sectionHeading)}>
        {heading}
      </p>
    </hgroup>
  )
}
