import React, { useState } from 'react'
import cn from 'classnames'
import useLocale from 'js/hooks/useLocale'
import {
  languages as allLanguages,
  translatedLanguageNames,
  language,
} from 'js/locale/constants'
import { linkClasses } from './Header'
import Chevron from 'media/chevron.svg?svgr-webpack'
import Check from './check.svg?svgr-webpack'
// for some weird reason eslint thought LanguageCodes was unused
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { LanguageCodes } from 'js/locale'
import { localizeLink, unlocalizePathname } from 'js/utils'
import { useLocation, useNavigate } from 'react-router-dom'

const navigationItemClasses =
  'text-white active:text-LSWhiteLinkActive focus:text-LSWhiteLinkHover hover:text-LSWhiteLinkHover transition inline-block ml-20 lg:ml-20 lg:mr-20'
const switcherClasses = `lg:type-20 type-[17px] ${navigationItemClasses} capitalize border-b-1 border-white/0 hover:border-LSWhiteLinkHover/100`

type Props = {
  inverted?: boolean
  isStuck?: boolean
  isLight?: boolean
  redirect?: boolean
  languages?: LanguageCodes[]
  onLanguageChange?: (LanguageCodes) => void
}

const LanguageSwitcher = ({
  inverted,
  isStuck,
  isLight,
  languages = allLanguages,
  onLanguageChange,
}: Props) => {
  const { language: currentLanguage, setLanguage } = useLocale()
  const [menuOpen, setMenuOpen] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const style = !isLight
    ? cn(linkClasses, 'relative uppercase')
    : switcherClasses

  return (
    <div
      className={cn(
        'relative text-left lg:text-inherit',
        inverted && !isStuck ? 'text-inherit' : 'text-BrandDark',
      )}
    >
      {menuOpen && (
        <div
          className="fixed left-0 top-0 h-full w-full"
          onClick={() => setMenuOpen(false)}
        />
      )}
      <button
        className={style}
        onClick={() => setMenuOpen(!menuOpen)}
        aria-controls="locale-dropdown"
        data-testid="language-list-toggler"
        data-uid="language-list-toggler"
        type="button"
      >
        <span className="sr-only">Language: </span>
        <span className="flex uppercase">
          {currentLanguage}
          <Chevron
            className={cn(
              'relative my-auto ml-6 transform',
              menuOpen && 'rotate-180',
              'bottom-[1px] h-[5px] w-[8px]',
            )}
          />
        </span>
      </button>
      <div id="locale-dropdown">
        {menuOpen && (
          <div
            className={cn(
              'absolute right-0 top-full lg:left-0 lg:right-auto',
              '-ml-12 mt-8 py-12',
              'rounded-8 bg-white shadow-elevated',
            )}
          >
            <ul>
              {languages.map((code) => {
                let href = '#'

                if (!onLanguageChange) {
                  href = code === language.ENGLISH ? '/' : `/${code}`
                }

                return (
                  <li key={code} lang={code} data-testid="language-list">
                    <a
                      href={href}
                      className={cn(
                        'relative block',
                        'py-6 pl-24 pr-80',
                        'transition hover:bg-grey-superlight',
                        currentLanguage === code
                          ? 'type-small-fat text-black'
                          : 'type-small text-Type',
                      )}
                      onClick={(e) => {
                        e.preventDefault()
                        setLanguage(code, false)
                        setMenuOpen(false)
                        navigate(
                          localizeLink(
                            unlocalizePathname(
                              location.pathname,
                            ) as '/pathname/',
                            code,
                          ),
                        )
                      }}
                      data-uid="language-list-toggler-language"
                    >
                      {currentLanguage === code && (
                        <Check className="absolute right-0 mr-24 origin-right scale-50 transform lg:scale-2/3" />
                      )}
                      <span>{translatedLanguageNames[code]}</span>
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default LanguageSwitcher
